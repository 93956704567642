.center {
  margin: auto;
  width: 50%;
  text-align: center;
}

input {
    width: 100%;
    font-size: 15px;
    margin-top: 10px ;
}
