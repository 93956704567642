.search-form {
    position: relative;
    display: flex;
    padding: 5px;
    align-items: center;

}

.search-label {
  width: 100px;
  text-align:center;
  box-sizing: border-box;
  font-weight: bold;
}

.search-input {
  margin:0;
  flex: 1;  
  margin-right:2px;
  box-sizing: border-box;
}

.search_hint {
  position: absolute;
  padding:0;
  margin: 0;
  width: 100px;
  margin-left: 1px;
  margin-top:1px;
background-color: #fff;
  left: 110px;
  top:40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.search_hint li {
  padding:3px;
  margin: 2px;
  cursor: pointer;
}

.search_hint li:hover {
  background-color: #eee;
}