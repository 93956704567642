.item_action_button {
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    margin-right: 5px;
    box-sizing: border-box;
  }