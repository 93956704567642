@keyframes ldio-arzaxdnne7c {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(22.5deg) }
  100% { transform: rotate(45deg) }
}
.ldio-arzaxdnne7c > div {
  transform-origin: 100px 100px;
  animation: ldio-arzaxdnne7c 0.8928571428571428s infinite linear;
}
.ldio-arzaxdnne7c > div div {
    position: absolute;
    width: 22px;
    height: 152px;
    background: #b0e15b;
    left: 100px;
    top: 100px;
    transform: translate(-50%,-50%);
}
.ldio-arzaxdnne7c > div div:nth-child(1) {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.ldio-arzaxdnne7c > div div:nth-child(6) {
    width: 80px;
    height: 80px;
    background: #ffffff;
    border-radius: 50%;
}.ldio-arzaxdnne7c > div div:nth-child(3) {
  transform: translate(-50%,-50%) rotate(45deg)
}.ldio-arzaxdnne7c > div div:nth-child(4) {
  transform: translate(-50%,-50%) rotate(90deg)
}.ldio-arzaxdnne7c > div div:nth-child(5) {
  transform: translate(-50%,-50%) rotate(135deg)
}
.loadingio-spinner-gear-xeghwc499r9 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-arzaxdnne7c {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-arzaxdnne7c div { box-sizing: content-box; }