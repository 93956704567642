.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    border-color: #ddd;
}

.title {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    font-weight: bold;
    padding: 10px;
}

.content {
    padding:5px;
}

.untitled-panel {
    margin-bottom: 20px;
    padding: 7px 0 7px 0;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}