.navbar li {
  display: inline;
  margin-right: 10px;
  padding:0;
}

.navbar{
  margin:0 0 10px 0;
}
.navbar-container {
  display: flex;
  width: 100%;
  padding:0;
}
.navbar-container > ul {
 flex:1;
 text-align: right;
 padding-bottom:15px;
}

.logo {
  font-weight: 700;
  font-family: "Istok Web";
  margin-bottom: 12px;
  padding: 0;
  font-size: 36px;
  color: #7CB720;
  text-decoration: none;
  align-self: flex-end;
}

.navbar a {
  color: #337ab7;
  text-decoration: none;
}
.navbar a:focus, .navbar a:hover {
  color: #23527c;
  text-decoration: underline;
  font-weight: normal;
}