ul.link-tags {
  padding: 0;
}

ul.link-tags li {
  display: inline;
  margin-right: 10px;
  margin-top: 15px;
}

ul.link_list {
  padding: 0;
  margin:0;
}
ul.link_list > li {
  padding:  10px 0 10px 0;
  border-bottom: 1px solid #e3e3e3;
}

.item_title {
  font-weight: 700;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 110%;
  margin-right:20px;
}

.item_url {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 0.9em;
}

.item_date {
  font-size: 0.9em;
}