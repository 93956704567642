.validatonErrors {
  color: red;
}
.tags {
  display: inline;
  position: relative
}
.hint {
  position: absolute;
  padding:0;
  width: 100px;
  margin-left: 1px;
  margin-top:18px;
  top: 25px;
  left: 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
.hint ul {
  padding:0;
  margin: 0;
}
.hint li {
  padding:3px;
  margin: 2px;
}
.hint li:hover {
  background-color: #eee;
}

.div-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .div-group {
    justify-content: flex-end;
  }
}


.div-group label {
  width: 100px;
  text-align:center;
  margin: 0;
  box-sizing: border-box;
  font-weight: bold;
  vertical-align: middle;
}

.div-group  .url-input {
  flex:1;
  margin-right:5px;
}

.tags {
  flex:1;
  padding:0;
  margin-right:5px;
  box-sizing: border-box;
  min-width: 100px;
}
.tag-input {
  flex:1;
  box-sizing: border-box;
  margin-right:5px;
}

@media (max-width: 600px) {
  .tags {
    flex:1;
  }
}

.div-group  green-button {
  flex:1;
  display: block;
}