.green-container {
    display: flex;
    background-color: #8CC63F;
    color: #ffffff;
    padding: 28px;
    justify-content: space-between;
    box-sizing: border-box;
}

.main-pic {
}

img {

}
.main-info {
    flex:1;
    padding-left:35px;
    margin:0;
}

.main-info h1 {
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 36px;
    line-height: normal;
}
.main-info h2{
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 30px;
}

.promo {
    font-size: 18px;
    background-color: #fff;
    color: #000;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    padding: 4px 4px 4px 10px;
   
}

.orange-button {
    background-color: #D36620;
    color: #fff;
    font-size: 20pt;
    font-weight: bold;
    margin-top: 20px;
    border-width: 0;
    padding: 10px 16px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.orange-button:hover {
    color: #F9F09B;
}

.landing-nav {
    display: flex;
}
.landing-nav .logo {
    font-size: 36pt;
    font-weight: bold;
    font-family: "Istok Web";
    color: #5e5e5e;
    margin-top: 10px;
}
.landing-nav .links {
  flex:1;
  text-align: right;
}
.landing-nav .links a{
    color: #666666;
    text-decoration: none;
    padding:0 25px 0 0;
    font-weight:bold;
    font-size:16px;
}